<template>
    <main class="col-lg-12 fill">
        <h1>Privacy Policy</h1>

        <p>This Privacy Policy governs the manner in which CDI collects, uses, maintains
            and discloses information collected from users (each, a &quot;User&quot;) of the cdicurbs.com
            website and mobile apps (collectively, &quot;Site&quot;).
            This privacy policy applies to the Site and all products and services offered by CDI.</p>

        <h2>Personal identification information</h2>

        <p>We may collect personal identification information from Users in a variety of ways, including, but not limited
            to, when Users visit our Site, register on the Site, place an order, respond to a survey, fill out a form, and in
            connection with other activities, services, features or resources we make available on our Site. Users may be asked
            for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously.
            We will collect personal identification information from Users only if they voluntarily submit such information to us.
            Users can always refuse to supply personal identification information, which may prevent them from engaging in certain Site related activities.</p>

        <h2>Non-personal identification information</h2>

        <p>We may collect non-personal identification information about Users whenever they interact with our Site.
            Non-personal identification information may include the IP address, browser name, the type of computer and
            technical information about Users&#39; means of connection to our Site, such as the operating system
            and the Internet service providers utilized and other similar information.</p>

        <h2>Web browser cookies</h2>

        <p>Our Site may use &quot;cookies&quot; to enhance User experience. A User&#39;s web browser places cookies on
            the User&#39;s hard drive for record-keeping purposes and sometimes to track information about the User.
            A User may choose to set its web browser to accept all cookies, refuse all cookies,
            or to alert you when cookies are being sent. If cookies are disabled, it may affect the Site&#39;s features and functions.</p>

        <h2>How we use collected information</h2>

        <p>CDI may collect and use Users personal information for
            the following purposes:</p>

        <ul class="body_content">
            <li>To improve customer service: Information you provide helps us respond to your customer service requests and support needs more efficiently.</li>
            <li>To personalize user experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.</li>
            <li>To improve our Site: We may use feedback you provide to improve our products and services.</li>
            <li>To run a promotion, contest, survey or other Site feature: To enter a contest, respond to a survey or use other Site features, a User agrees to receive information  about topics we think will be of interest to them.</li>
            <li>To send periodic emails: We may use the email address to send Users information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests.</li>
        </ul>

        <h2>How we protect your information</h2>

        <p>CDI has industry standard data collection, storage and processing practices and security measures in place in an effort to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site. CDI takes reasonable steps to maintain and protect information, however CDI cannot guarantee the standards will always be met. There may be factors beyond CDI&#39;s control that may result in disclosure of information. CDI disclaims warranties and representations relating to maintenance or nondisclosure of information.</p>

        <h2>Sharing your personal information</h2>

        <p>We do not sell, trade, or rent Users&#39; personal
            identification information to others. We may share generic aggregated
            demographic information not linked to any personal identification
            information regarding visitors and users with our business partners,
            trusted affiliates and advertisers for the purposes outlined above.</p>

        <h2>Photos and Files</h2>

        <p>Our Site collects photos and files created by both CDI and submitted by Users.  Photos may be used for promotion of our services.  These promotions may be on our Site and other media, such as mobile apps and print. By submitting a photograph to CDI, you (1) agree that CDI will have the unrestricted right to use and to publish your photographs in print or on its Site or on any social media networks; and (2) certify that you are at least 18 years old, you own the copyright to the photographs or otherwise have permission from the copyright owner to submit them for publication, and that CDI&#39;s use will not violate any third party&#39;s rights.</p>

        <h2>Third party websites</h2>

        <p>Users may find advertising or other content on our Site
            that link to the sites and services of our partners, suppliers,
            advertisers, sponsors, licensors and other third parties. We do not
            control the content or links that appear on these sites and are not
            responsible for the practices employed by websites linked to or from
            our Site. In addition, these sites or services, including their
            content and links, may be constantly changing. These sites and
            services may have their own privacy policies and customer service
            policies. Browsing and interaction on any other website, including
            websites which have a link to our Site, is subject to that
            website&#39;s own terms and policies.</p>

        <h2>International</h2>

        <p>This Site is intended for Users located in the US. If this Site is being accessed by users outside of the US, be advised that this Site is governed by the laws of the US, CDI&#39;s Terms and Conditions, and this Privacy Policy, which may vary from the laws of other areas outside the US. By accessing this Site, you are transferring information to the US and you consent to such transfer.</p>

        <h2>Changes to this privacy policy</h2>

        <p>CDI reserves the right to update this privacy policy at
            any time. When we do, we will revise the updated date at the bottom
            of this page. We encourage Users to frequently check this page for
            any changes to stay informed about how we are helping to protect the
            personal information we collect. You acknowledge and agree that it is
            your responsibility to review this privacy policy periodically and
            become aware of modifications.</p>

        <h2>Your acceptance of these terms</h2>

        <p>Please also visit CDI&#39;s Terms and Conditions section establishing the use, disclaimers and limitations of liability at <router-link to="/termsandconditions"> Terms and Conditions page</router-link>. By your continued use of this Site, you signify your acceptance of the Terms and Conditions as well as this Privacy Policy. If you do not agree to this Privacy Policy, please do not use our Site. Your continued use of the Site following the posting of changes to the Terms and Conditions or this Privacy Policy will be deemed your acceptance of those changes.</p>

        <h2>Contacting us</h2>

        <p>If you have any questions about this Privacy Policy, the
            practices of this Site, or your dealings with this Site, please
            contact us.</p>

        <p>Mail: Attention CDI
            17560 Tyler Street NW
            Elk River, MN 55330.</p>

        <p>Email: webmaster@cdicurbs.com</p>

        <p>Phone: 1.888.234.7001 | 763.391.7790</p>

        <p><i>Last updated: April 2013</i></p>

        <h2>&#169; Crystal Distribution Inc.</h2>
    </main>
</template>

<script>
    export default {
        name: "PrivacyPolicy",
        created() {
            this.$appStore.setTitle(['Privacy Policy']);
        },
    }
</script>

<style scoped>

</style>